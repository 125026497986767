import {
  Box,
  Button,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Image,
  Link,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Square,
  Stack,
  Text,
  useBreakpointValue,
  useColorMode,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import {
  ChevronDownIcon,
  ChevronRightIcon,
  CloseIcon,
  HamburgerIcon,
} from '@chakra-ui/icons';
import { IoMoonSharp, IoSunnySharp } from "react-icons/io5";
import React, { useEffect, useState } from "react";
import {
  accountPageURL,
  contactPageURL,
  cookiePageURL,
  cryptoPageURL,
  disclaimerPageURL,
  dividendchartPageURL,
  historicalPageURL,
  homePageURL,
  howtoPageURL,
  loginPageURL,
  momentumchartPageURL,
  passwordResetPageURL,
  privacyPageURL,
  settingsPageURL,
  signinPageURL,
  signupPageURL,
  subscribePageURL,
  termsPageURL,
} from '../constants';
import { authCheckState, authClearErr, logout } from "../../store/actions/auth";

import { CgLogOut } from "react-icons/cg";
import NextLink from 'next/link';
import SigninForm from "./SigninForm";
import { VscAccount } from "react-icons/vsc";
import { connect } from "react-redux";
import { profileClear } from "../../store/actions/profile";
import { withRouter } from 'next/router'

const Navbar = function Navbar(props) {
  const isHomePage = props.router.route === homePageURL;
  const { isOpen, onToggle } = useDisclosure();
  // const linkColor = useColorModeValue('gray.600', 'gray.200');
  const _linkColor = useColorModeValue('blue.400', 'white')
  const linkColor = isHomePage ? _linkColor : 'white';
  // const linkHoverColor = useColorModeValue('gray.800', 'white');
  const linkHoverColor = 'blue.300'
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');
  const popoverContentTextColor = useColorModeValue('black', 'white');
  const popoverButtonHoverBgColor = useColorModeValue('blue.50', 'gray.900');
  const backgroundColor = useColorModeValue('whiteAlpha.400', 'blackAlpha.100');
  const themeIcon = useColorModeValue(<Icon as={IoSunnySharp} />, <Icon as={IoMoonSharp} />)
  const { colorMode, toggleColorMode } = useColorMode();
  const [followSystemTheme, setFollowSystemTheme] = useState(false);
  const [logoutPressed, setLogoutPressed] = useState(false);

  const [accountMenuIsOpen, setAccountMenuIsOpen] = React.useState(false)
  const openAccount = () => setAccountMenuIsOpen(!isOpen)
  const closeAccount = () => setAccountMenuIsOpen(false)
  const { isOpen: isLoginOpen, onOpen, onClose } = useDisclosure();
  const signinFocusRef = React.useRef()

  const logoAlignment = useBreakpointValue({ base: 'center', lg: 'left' });
  const homePageLogoImage = useColorModeValue(
    <Image
      src='/logos/Symbol.svg'
      alt='Logo'
      fit='scale-down'
      height={'45px'}
      align={logoAlignment}
      display={{ base: 'none', lg: 'block' }}
    />,
    <Image
      src='/logos/All White.png'
      alt='Logo'
      fit='scale-down'
      height={'45px'}
      align={logoAlignment}
      display={{ base: 'none', lg: 'block' }}
    />
  )

  function updateColorPref(e) {
    console.log(e.matches, colorMode)
    if ((e.matches && colorMode === 'light') || (!e.matches && colorMode === 'dark')) {
      console.log('updateColorPref')
      toggleColorMode()
    }
  }

  useEffect(() => {
    if (followSystemTheme) {
      const supportsColorScheme = window.matchMedia("(prefers-color-scheme)");
      const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)');

      if (supportsColorScheme.matches) {
        try {
          // Chrome & Firefox
          isDarkMode.addEventListener('change', updateColorPref);
          return () => {
            isDarkMode.removeEventListener('change', updateColorPref);
          }
        } catch (e1) {
          try {
            // Safari
            isDarkMode.addListener(updateColorPref);
            return () => {
              isDarkMode.removeListener(updateColorPref);
            }
          } catch (e2) {
            console.error(e2);
          }
        }
      }
    }
  }, [followSystemTheme, colorMode])

  useEffect(() => {
    if (!props.authenticated) {
      setLogoutPressed(false)
    }
  }, [props.authenticated])

  if (props.authToken == null) {
    return <Box />
  }

  return (
    <Box
      position={props.position}
      // bg={isHomePage ? backgroundColor : undefined}
      borderRadius={'xl'}
    >
      <Flex
        // bg={useColorModeValue('white', 'gray.800')}
        bg={'transparent'}
        // color={useColorModeValue('gray.600', 'white')}
        color={linkColor}
        minH={'60px'}
        py={{ base: 1 }}
        px={{ base: 4 }}
        // borderBottom={1}
        // borderStyle={'solid'}
        // borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}>
        <Flex
          flex={{ base: 1, lg: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', lg: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', lg: 'start' }} align='center'>
          <Box
            boxsize={'100%'}
            mr={6}
          >
            <NextLink href={homePageURL} passHref legacyBehavior>
              <Link>
                <Button
                  p={0}
                  boxSize={'50px'}
                  bg={'transparent'}
                  _hover={{
                    bg: 'whiteAlpha.400'
                  }}
                >
                  {isHomePage ? homePageLogoImage :
                    <Image
                      src='/logos/All White.png'
                      alt='Logo'
                      fit='scale-down'
                      height={'45px'}
                      align={logoAlignment}
                      display={{ base: 'none', lg: 'block' }}
                    />
                  }
                </Button>
              </Link>
            </NextLink>
          </Box>
          <Flex display={{ base: 'none', lg: 'flex' }} ml={0}>
            <DesktopNav isHomePage={isHomePage} subscribed={props.subscribed} />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={'flex-end'}
          direction={'row'}
          align={'center'}
          spacing={6}
        >
          <Popover
            trigger={'hover'}
            placement={'bottom'}
          >
            {({ onClose }) => (
              <>
                <PopoverTrigger>
                  <IconButton
                    className={'theme-popover-trigger'}
                    aria-label='Site theme'
                    icon={themeIcon}
                    color={linkColor}
                    bg={'transparent'}
                    _hover={{
                      bg: 'whiteAlpha.400'
                    }}
                  />
                </PopoverTrigger>
                <Portal>
                  <PopoverContent
                    border={0}
                    boxShadow={'xl'}
                    bg={popoverContentBgColor}
                    p={4}
                    rounded={'xl'}
                    w={'max'}
                    color={popoverContentTextColor}
                  >
                    <PopoverArrow bg={popoverContentBgColor} />
                    {/* <PopoverCloseButton /> */}
                    <Stack>
                      <Box
                        id={'light-mode-button'}
                        role={'group'}
                        display={'block'}
                        p={2}
                        rounded={'md'}
                        _hover={colorMode === 'dark' ? { bg: popoverButtonHoverBgColor, color: linkHoverColor } : {}}
                        transition={'all .3s ease'}
                        fontWeight={colorMode === 'dark' ? 500 : 800}
                        cursor={colorMode === 'dark' ? 'pointer' : undefined}
                        onClick={() => {
                          if (colorMode === 'dark') {
                            // console.log('set light mode')
                            toggleColorMode();
                            localStorage['chakra-ui-theme-use-system-color-mode'] = false;
                            setFollowSystemTheme(false);
                            onClose();
                          }
                        }}
                      >
                        <Stack direction={'row'} align={'center'}>
                          <Icon as={IoSunnySharp} />
                          <Text>
                            {'Light Mode'}
                          </Text>
                        </Stack>
                      </Box>
                      <Box
                        id={'dark-mode-button'}
                        role={'group'}
                        display={'block'}
                        p={2}
                        rounded={'md'}
                        _hover={colorMode === 'light' ? { bg: popoverButtonHoverBgColor, color: linkHoverColor } : {}}
                        transition={'all .3s ease'}
                        fontWeight={colorMode === 'light' ? 500 : 800}
                        cursor={colorMode === 'light' ? 'pointer' : undefined}
                        onClick={() => {
                          if (colorMode === 'light') {
                            // console.log('set dark mode')
                            toggleColorMode();
                            localStorage['chakra-ui-theme-use-system-color-mode'] = false;
                            setFollowSystemTheme(false);
                            onClose();
                          }
                        }}
                      >
                        <Stack direction={'row'} align={'center'}>
                          <Icon as={IoMoonSharp} />
                          <Text>
                            {'Dark Mode'}
                          </Text>
                        </Stack>
                      </Box>
                      <Box
                        role={'group'}
                        display={'block'}
                        p={2}
                        rounded={'md'}
                        _hover={{ bg: popoverButtonHoverBgColor, color: linkHoverColor }}
                        transition={'all .3s ease'}
                        fontWeight={500}
                        cursor={'pointer'}
                        onClick={() => {

                          const supportsColorScheme = window.matchMedia("(prefers-color-scheme)");
                          const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
                          const isLightMode = window.matchMedia('(prefers-color-scheme: light)');

                          if (supportsColorScheme.matches) {
                            if (isDarkMode.matches && colorMode === 'light') {
                              console.log('follow system dark')
                              toggleColorMode()
                            } else if (isLightMode.matches && colorMode === 'dark') {
                              console.log('follow system light')
                              toggleColorMode()
                            }
                          }

                          localStorage['chakra-ui-theme-use-system-color-mode'] = true;
                          setFollowSystemTheme(true);
                          onClose();
                        }}
                      >
                        <Stack direction={'row'} align={'center'}>
                          <Text>
                            {'Follow System Theme'}
                          </Text>
                        </Stack>
                      </Box>
                    </Stack>
                  </PopoverContent>
                </Portal>
              </>
            )}
          </Popover>
          {!props.authenticated &&
            <>
              <NextLink href={signupPageURL} passHref legacyBehavior>
                <Link>
                  <Button
                    fontSize={'md'}
                    fontWeight={700}
                    color={linkColor}
                    bg={'transparent'}
                    _hover={{
                      bg: 'whiteAlpha.400',
                    }}
                  >
                    Sign Up
                  </Button>
                </Link>
              </NextLink>
              {props.signinRedirect && (
                <NextLink href={signinPageURL} passHref legacyBehavior>
                  <Link>
                    <Button
                      className={'signin-popover-trigger'}
                      fontSize={'md'}
                      fontWeight={700}
                      color={linkColor}
                      bg={'transparent'}
                      _hover={{
                        bg: 'whiteAlpha.400',
                      }}
                      onClick={onOpen}
                    >
                      Sign In
                    </Button>
                  </Link>
                </NextLink>
              ) || (
                  <Popover
                    trigger={'click'}
                    placement={'bottom'}
                    isOpen={isLoginOpen || props.isOpen}
                    onClose={() => { onClose(); if (props.onClose) props.onClose(); }}
                    closeOnBlur={false}
                    initialFocusRef={signinFocusRef}
                  >
                    <PopoverTrigger>
                      <Button
                        className={'signin-popover-trigger'}
                        fontSize={'md'}
                        fontWeight={700}
                        color={linkColor}
                        bg={'transparent'}
                        _hover={{
                          bg: 'whiteAlpha.400',
                        }}
                        onClick={onOpen}
                      >
                        Sign In
                      </Button>
                    </PopoverTrigger>
                    <Portal>
                      <PopoverContent
                        color={popoverContentTextColor}
                        w={'max'}
                      >
                        <PopoverArrow bg={popoverContentBgColor} />
                        <PopoverCloseButton />
                        <SigninForm signinFocusRef={signinFocusRef} />
                      </PopoverContent>
                    </Portal>
                  </Popover>
                )}
            </> ||
            <Popover trigger={'hover'} placement={'bottom'} onClose={closeAccount} isOpen={accountMenuIsOpen && !logoutPressed}>
              <PopoverTrigger>
                <Button
                  className={'account-popover-trigger'}
                  fontSize={'md'}
                  fontWeight={700}
                  color={'white'}
                  bg={'blue.400'}
                  _hover={{
                    bg: 'blue.300',
                  }}
                  isLoading={logoutPressed}
                  loadingText={'Logging Out'}
                  onClick={openAccount}
                >
                  {props.account}
                </Button>
              </PopoverTrigger>
              <Portal>
                <PopoverContent
                  border={0}
                  boxShadow={'xl'}
                  bg={popoverContentBgColor}
                  p={4}
                  rounded={'xl'}
                  w={'max'}
                  color={popoverContentTextColor}
                >
                  <PopoverArrow bg={popoverContentBgColor} />
                  <Stack>
                    <Box
                      role={'group'}
                      display={'block'}
                      p={2}
                      rounded={'md'}
                      _hover={{ bg: popoverButtonHoverBgColor, color: linkHoverColor }}
                      transition={'all .3s ease'}
                      fontWeight={500}
                      cursor={'pointer'}
                    >
                      <NextLink href={accountPageURL} passHref legacyBehavior>
                        <Link _hover={{ textDecoration: 'unset' }}>
                          <Stack direction={'row'} align={'center'}>
                            <Icon as={VscAccount} />
                            <Text>
                              {'Account'}
                            </Text>
                          </Stack>
                        </Link>
                      </NextLink>
                    </Box>
                    <Box
                      id={'logout-button'}
                      role={'group'}
                      display={'block'}
                      p={2}
                      rounded={'md'}
                      _hover={{ bg: popoverButtonHoverBgColor, color: linkHoverColor }}
                      transition={'all .3s ease'}
                      fontWeight={500}
                      cursor={'pointer'}
                      onClick={() => {
                        props.logout(props.authToken)
                        setLogoutPressed(true)
                      }
                      }
                    >
                      <Stack direction={'row'} align={'center'}>
                        <Icon as={CgLogOut} />
                        <Text>
                          {'Logout'}
                        </Text>
                      </Stack>
                    </Box>
                  </Stack>
                </PopoverContent>
              </Portal>
            </Popover>
          }
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav isHomePage={isHomePage} subscribed={props.subscribed} />
      </Collapse>
    </Box>
  );
}

const DesktopNav = (props) => {
  // const linkColor = useColorModeValue('gray.600', 'gray.200');
  const _linkColor = useColorModeValue('blue.400', 'white')
  const linkColor = props.isHomePage ? _linkColor : 'white';
  // const linkHoverColor = useColorModeValue('gray.800', 'white');
  //const linkHoverColor = 'blue.400'
  const linkHoverColor = 'blue.300'
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          {/* <Popover trigger={'hover'} placement={'bottom-start'}> */}
          {/* <PopoverTrigger> */}
          <Box>
            <NextLink href={navItem.href ?? '#'} passHref legacyBehavior>
              <Link>
                <Button
                  display={{ base: 'inline-flex' }}
                  fontSize={'md'}
                  fontWeight={700}
                  color={linkColor}
                  bg={'transparent'}
                  _hover={{
                    bg: 'whiteAlpha.400'
                  }}
                >
                  {navItem.label}
                </Button>
              </Link>
            </NextLink>
          </Box>
          {/* </PopoverTrigger> */}

          {/* {
              navItem.children && (
                <PopoverContent
                  border={0}
                  boxShadow={'xl'}
                  bg={popoverContentBgColor}
                  p={4}
                  rounded={'xl'}
                  minW={'sm'}
                >
                  <Stack>
                    {navItem.children.map((child) => (
                      <DesktopSubNav key={child.label} {...child} />
                    ))}
                  </Stack>
                </PopoverContent>
              )
            } */}
          {/* </Popover> */}
        </Box>
      ))
      }
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }) => {

  const popoverButtonHoverBgColor = useColorModeValue('blue.50', 'gray.900');

  return (
    <NextLink href={href} passHref legacyBehavior>
      <Link
        role={'group'}
        display={'block'}
        p={2}
        rounded={'md'}
        _hover={{ bg: popoverButtonHoverBgColor }}>
        <Stack direction={'row'} align={'center'}>
          <Box>
            <Text
              transition={'all .3s ease'}
              _groupHover={{ color: 'blue.400' }}
              fontWeight={500}>
              {label}
            </Text>
            <Text fontSize={'sm'}>{subLabel}</Text>
          </Box>
          <Flex
            transition={'all .3s ease'}
            transform={'translateX(-10px)'}
            opacity={0}
            _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
            justify={'flex-end'}
            align={'center'}
            flex={1}>
            <Icon color={'blue.400'} w={5} h={5} as={ChevronRightIcon} />
          </Flex>
        </Stack>
      </Link>
    </NextLink>
  );
};

const MobileNav = (props) => {

  const backgroundColor = useColorModeValue('white', 'gray.800');
  const boxShadowColor = useColorModeValue('lg', 'dark-lg');

  return (
    <Stack
      bg={backgroundColor}
      p={4}
      display={{ lg: 'none' }}
      boxShadow={boxShadowColor}
      mb={'17px'}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();
  const textColor = useColorModeValue('gray.600', 'gray.200');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <NextLink href={href ?? '#'} passHref legacyBehavior>
        <Link>
          <Flex
            py={2}
            justify={'space-between'}
            align={'center'}
            _hover={{
              textDecoration: 'none',
            }}>
            <Text
              fontWeight={600}
              color={textColor}>
              {label}
            </Text>
            {children && (
              <Icon
                as={ChevronDownIcon}
                transition={'all .25s ease-in-out'}
                transform={isOpen ? 'rotate(180deg)' : ''}
                w={6}
                h={6}
              />
            )}
          </Flex>
        </Link>
      </NextLink>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={borderColor}
          align={'start'}>
          {children &&
            children.map((child) => (
              <NextLink href={child.href} passHref legacyBehavior key={child.label}>
                <Link py={2}>
                  {child.label}
                </Link>
              </NextLink>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

// interface NavItem {
//   label: string;
//   subLabel?: string;
//   children?: Array<NavItem>;
//   href?: string;
// }

const NAV_ITEMS = [
  {
    label: 'Home',
    href: homePageURL
  },
  {
    label: 'Stock Table',
    href: momentumchartPageURL
  },
  // {
  //   label: 'Crypto',
  //   href: cryptoPageURL
  // },
  {
    label: 'Upgrade',
    href: subscribePageURL
  },
  {
    label: 'How To',
    href: howtoPageURL
  },
  {
    label: 'Contact Us',
    href: contactPageURL
  },
  // {
  //   label: 'Inspiration',
  //   children: [
  //     {
  //       label: 'Explore Design Work',
  //       subLabel: 'Trending Design to inspire you',
  //       href: '#',
  //     },
  //     {
  //       label: 'New & Noteworthy',
  //       subLabel: 'Up-and-coming Designers',
  //       href: '#',
  //     },
  //   ],
  // },
  // {
  //   label: 'Find Work',
  //   children: [
  //     {
  //       label: 'Job Board',
  //       subLabel: 'Find your dream design job',
  //       href: '#',
  //     },
  //     {
  //       label: 'Freelance Projects',
  //       subLabel: 'An exclusive list for contract work',
  //       href: '#',
  //     },
  //   ],
  // },
  // {
  //   label: 'Learn Design',
  //   href: '#',
  // },
  // {
  //   label: 'Hire Designers',
  //   href: '#',
  // },
];

const mapStateToProps = state => {
  return {
    authenticated: state.auth.token != null && state.auth.token != 'unauthenticated',
    authToken: state.auth.token,
    account: state.auth.account,
    subscribed: (state.userProfile?.userProfile?.subscription == '1') || (state.userProfile?.userProfile?.subscription == '2'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logout: (authToken) => dispatch(logout(authToken)),
    authCheckState: () => dispatch(authCheckState()),
    authClear: () => dispatch(authClearErr()),
    profileClear: () => dispatch(profileClear()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Navbar));