import {
  PROFILE_START,
  PROFILE_SUCCESS,
  PROFILE_FAIL,
  PROFILE_GET_FAVORITES,
  PROFILE_CREATE_FAVORITES,
  PROFILE_PATCH_FAVORITES,
  PROFILE_CLEAR,
  PROFILE_DELETE_FAVORITE
} from "./actionTypes";
import { authAxios } from "../../components/authAxios";
import { favoritesURL, profileURL } from "../../components/constants";
import Axios from "axios";

export const profileStart = () => {
  return {
    type: PROFILE_START
  };
};

export const profileSuccess = data => {
  return {
    type: PROFILE_SUCCESS,
    data
  };
};

export const profileFail = error => {
  return {
    type: PROFILE_FAIL,
    error: error
  };
};

export const profileGetFavorites = data => {
  return {
    type: PROFILE_GET_FAVORITES,
    data
  };
}

export const profileCreateFavorites = data => {
  return {
    type: PROFILE_CREATE_FAVORITES,
    data
  };
};

export const profilePatchFavorites = data => {
  return {
    type: PROFILE_PATCH_FAVORITES,
    data
  };
};

export const clear = () => {
  return {
    type: PROFILE_CLEAR
  }
}

export const clearNewFavoritesList = () => {
  return {
    type: PROFILE_CLEAR,
    newFavorites: true
  }
}

export const deleteFavoriteList = () => {
  return {
    type: PROFILE_DELETE_FAVORITE
  }
}

export const fetchUserProfile = (auth) => {
  // if (localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== null) {
  // console.log(auth)
  if (auth !== null && auth !== 'unauthenticated') {
    // console.log(localStorage.getItem('token'))
    return dispatch => {
      dispatch(profileStart());
      authAxios(auth)
        .get(profileURL)
        .then(res => {
          dispatch(profileSuccess(res.data));
        })
        .catch(err => {
          dispatch(profileFail(err));
        });
    };
  } else {
    return dispatch => {
      dispatch(profileSuccess({
        "subscription": "",
        "status": "unauthenticated",
        "free_trial_end_date": "",
        "paid_until": ""
      }))
    }
  }
};

export const getFavoritesList = (auth, exchange) => {
  return dispatch => {
    dispatch(profileStart());
    authAxios(auth)
      .get(favoritesURL, {
        params: {
          screener: exchange
        }
      })
      .then(res => {
        dispatch(profileGetFavorites({ data: res.data, exchange }));
      })
      .catch(err => {
        dispatch(profileFail(err));
      });
  }
}

export const createFavoritesList = (auth, list, exchange) => {
  return dispatch => {
    dispatch(profileStart());
    authAxios(auth)
      .post(favoritesURL, {
        ...list,
        screener: exchange
      })
      .then(res => {
        dispatch(profileCreateFavorites({ data: res.data, exchange }));
      })
      .catch(err => {
        dispatch(profileFail(err));
      });
  }
}

export const patchFavoritesList = (auth, list, exchange) => {
  return dispatch => {
    dispatch(profileStart())
    authAxios(auth)
      .patch(favoritesURL, {
        ...list,
        screener: exchange
      })
      .then(res => {
        dispatch(profilePatchFavorites({ data: res.data, exchange }));
      })
      .catch(err => {
        dispatch(profileFail(err));
      });
  }
}

export const deleteFavoritesList = (auth, hashid, exchange) => {
  return dispatch => {
    dispatch(profileStart())
    authAxios(auth)
      .delete(favoritesURL, {
        data: {
          hashid: hashid,
          screener: exchange
        }
      })
      .then(res => {
        dispatch(deleteFavoriteList(res));
      })
      .catch(err => {
        dispatch(profileFail(err));
      });
  }
}

export const patchProfile = (auth, profileUpdate) => {
  return dispatch => {
    dispatch(profileStart())
    authAxios(auth)
      .patch(profileURL, {
        ...profileUpdate
      })
      .then(res => {
        dispatch(profileSuccess(res.data));
      })
      .catch(err => {
        dispatch(profileFail(err));
      });
  }
}


export const profileClear = () => {
  return dispatch => {
    dispatch(clear())
  }
}
