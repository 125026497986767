import {
  AUTH_CREATED,
  AUTH_EMAIL_CHANGE,
  AUTH_EMAIL_VERIFY,
  AUTH_FAIL,
  AUTH_LOGOUT,
  AUTH_PASSWORD_CHANGE,
  AUTH_PASSWORD_RESET,
  AUTH_PASSWORD_VERIFIED,
  AUTH_PASSWORD_VERIFY,
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_VERIFIED,
  CLEAR_ERR
} from "./actionTypes";
import {
  deleteAccountURL,
  emailChangeURL,
  emailChangeVerifyURL,
  loginURL,
  logoutURL,
  passwordChangeURL,
  passwordResetURL,
  passwordResetVerifiedURL,
  passwordResetVerifyURL,
  registerURL,
  registerVerifyURL,
  userMeURL
} from "../../components/constants";

import { authAxios } from "../../components/authAxios";
import axios from "axios";

export const authStart = () => {
  return {
    type: AUTH_START
  };
};

export const authSuccess = (token, account, data) => {
  return {
    type: AUTH_SUCCESS,
    token: token,
    account: account,
    data: data,
  };
};

export const authCreated = () => {
  return {
    type: AUTH_CREATED,
    created: true
  };
};

export const authVerified = () => {
  return {
    type: AUTH_VERIFIED,
    verified: true
  }
}

export const authFail = error => {
  return {
    type: AUTH_FAIL,
    error: error
  };
};

export const authLogout = () => {
  return {
    type: AUTH_LOGOUT,
    token: 'unauthenticated'
  };
};

export const authPasswordReset = () => {
  return {
    type: AUTH_PASSWORD_RESET,
    password_reset: 'submitted'
  }
}

export const authPasswordVerify = () => {
  return {
    type: AUTH_PASSWORD_VERIFY,
    password_reset: 'code_verified'
  }
}

export const authPasswordVerified = () => {
  return {
    type: AUTH_PASSWORD_VERIFIED,
    password_reset: 'password_reset'
  }
}

export const authPasswordChange = () => {
  return {
    type: AUTH_PASSWORD_CHANGE,
    password_reset: 'password_change'
  }
}

export const authEmailChange = () => {
  return {
    type: AUTH_EMAIL_CHANGE,
    email_change_status: 'email_sent'
  }
}

export const authEmailChangeVerify = () => {
  return {
    type: AUTH_EMAIL_VERIFY,
    email_change_status: 'email_changed'
  }
}

export const clearErr = () => {
  return {
    type: CLEAR_ERR
  }
}

// export const checkAuthTimeout = expirationTime => {
//   return dispatch => {
//     setTimeout(() => {
//       dispatch(logout());
//     }, expirationTime * 1000);
//   };
// };

export const login = (email, password) => {
  return dispatch => {
    dispatch(authStart());
    authAxios()
      .post(loginURL, {
        email: email,
        password: password
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(res => {
        // Get the auth_token cookie from the response
        const token = 'Token stored in cookie now';
        const account = res.data.user.email;
        // const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
        localStorage.setItem("token", token);
        localStorage.setItem("account", account)
        // localStorage.setItem("expirationDate", expirationDate);
        dispatch(authSuccess(token, account, res));
        // dispatch(checkAuthTimeout(3600));
      })
      .catch(err => {
        dispatch(authFail(err));
      });
  };
};

export const logout = (auth) => {
  return dispatch => {
    dispatch(authStart());
    localStorage.removeItem("token");
    localStorage.removeItem("account");
    authAxios(auth)
      .post(logoutURL)
      .then(res => {
        dispatch(authLogout());
      })
      .catch(err => {
        dispatch(authFail(err));
      });
  }
}

export const signup = (email, password, password2) => {
  return dispatch => {
    dispatch(authStart());
    axios
      .post(registerURL, {
        email: email,
        password: password,
        password2: password2
      })
      .then(res => {
        dispatch(authCreated());
        // console.log(res)
        // const token = res.data.key;
        // // const expirationDate = new Date(new Date().getTime() + 3600 * 1000);
        // localStorage.setItem("token", token);
        // // localStorage.setItem("expirationDate", expirationDate);
        // dispatch(authSuccess(token));
        // // dispatch(checkAuthTimeout(3600));
      })
      .catch(err => {
        dispatch(authFail(err));
      });
  };
};

export const registerVerify = (code) => {
  return dispatch => {
    dispatch(authStart());
    axios
      .get(registerVerifyURL, {
        params: {
          code: code
        }
      })
      .then(res => {
        dispatch(authVerified());
      })
      .catch(err => {
        dispatch(authFail(err));
      });
  };
};

export const passwordReset = (email) => {
  return dispatch => {
    dispatch(authStart());
    axios
      .post(passwordResetURL, {
        email: email
      })
      .then(res => {
        dispatch(authPasswordReset());
      })
      .catch(err => {
        dispatch(authFail(err));
      })
  }
}

export const passwordResetVerify = (code) => {
  return dispatch => {
    dispatch(authStart());
    axios
      .get(passwordResetVerifyURL, {
        params: {
          code: code
        }
      })
      .then(res => {
        dispatch(authPasswordVerify())
      })
      .catch(err => {
        dispatch(authFail(err))
      })
  }
}

export const passwordResetVerified = (code, password) => {
  return dispatch => {
    dispatch(authStart());
    axios
      .post(passwordResetVerifiedURL, {
        code: code,
        password: password
      })
      .then(res => {
        dispatch(authPasswordVerified())
      })
      .catch(err => {
        dispatch(authFail(err))
      })
  }
}

export const passwordChange = (auth, oldPassword, password) => {
  return dispatch => {
    dispatch(authStart());
    authAxios(auth)
      .post(passwordChangeURL, {
        oldPassword: oldPassword,
        password: password
      })
      .then(res => {
        dispatch(authPasswordChange())
      })
      .catch(err => {
        dispatch(authFail(err))
      })
  }
}

export const emailChange = (auth, email) => {
  return dispatch => {
    dispatch(authStart());
    authAxios(auth)
      .post(emailChangeURL, {
        email: email
      })
      .then(res => {
        dispatch(authEmailChange())
      })
      .catch(err => {
        dispatch(authFail(err))
      })
  }
}

export const emailChangeVerify = (code) => {
  return dispatch => {
    dispatch(authStart());
    axios
      .get(emailChangeVerifyURL, {
        params: {
          code: code
        }
      })
      .then(res => {
        const token = localStorage.getItem("token");

        if (token != undefined) {
          dispatch(logout(token))
        }

        dispatch(authEmailChangeVerify())
      })
      .catch(err => {
        dispatch(authFail(err))
      })
  }
}

export const authCheckState = () => {
  return dispatch => {
    dispatch(authStart());
    // console.log('checking state')

    const token = localStorage.getItem("token");
    const account = localStorage.getItem("account");

    // console.log(token, account, token == undefined, account == undefined)
    if (token == undefined || account == undefined) {
      // console.log('dispatch authLogout')
      dispatch(authLogout());

    } else {
      authAxios(token)
        .get(userMeURL)
        .then(res => {
          dispatch(authSuccess(token, account, res));
        })
        .catch(err => {
          localStorage.removeItem("token");
          localStorage.removeItem("account");
          dispatch(authLogout());
        })
    }
  };
};

export const authClearErr = () => {
  return dispatch => {
    dispatch(clearErr())
  }
}

export const deleteAccount = (auth) => {
  return dispatch => {
    dispatch(authStart())
    authAxios(auth)
      .delete(deleteAccountURL)
      .then(res => {
        dispatch(authLogout());
      })
      .catch(err => {
        dispatch(authFail(err));
      });
  }
}

